import request from 'utils/request';

const api = {
  AFFILIATE_LIST: '/api/affiliate/overall/list',
  AFFILIATE_ADD: '/api/affiliate/overall/add',
  AFFILIATE_UPDATE: '/api/affiliate/overall/update',
  AFFILIATE_UPDATE_PAYOUT: '/api/affiliate/overall/updatePayOut',
  AFFILIATEADJUSTBLACKOFFER_DELETE: '/api/AffiliateAdjustBlackOffer/remove',
  AFFILIATEADJUSTBLACKOFFER_SAVE: '/api/AffiliateAdjustBlackOffer/save',
  AFFILIATEADJUSTBLACKOFFER_UPDATE: '/api/AffiliateAdjustBlackOffer/update',
  AFFILIATEADJUSTBLACKOFFER_LIST: '/api/AffiliateAdjustBlackOffer/selectAll',
};
export function getAffiliateList(query) {
  return request({
    url: api.AFFILIATE_LIST,
    method: 'get',
    params: query,
  });
}
export function insertAffiliate(data) {
  return request({
    url: api.AFFILIATE_ADD,
    method: 'post',
    data,
  });
}
export function updateAffiliate(data) {
  return request({
    url: api.AFFILIATE_UPDATE,
    method: 'put',
    data,
  });
}

export function updatePayout(data) {
  return request({
    url: api.AFFILIATE_UPDATE_PAYOUT,
    method: 'post',
    data,
  });
}

export function updateAffiliateAdjustBlackOffer(data) {
  return request({
    url: api.AFFILIATEADJUSTBLACKOFFER_UPDATE,
    method: 'post',
    data,
  });
}

export function insertAffiliateAdjustBlackOffer(data) {
  return request({
    url: api.AFFILIATEADJUSTBLACKOFFER_SAVE,
    method: 'post',
    data,
  });
}

export function getAffiliateAdjustBlacks(query) {
  return request({
    url: api.AFFILIATEADJUSTBLACKOFFER_LIST,
    method: 'get',
    params: query,
  });
}

export function removeAffiliateAdjustBlack(id) {
  return request({
    url: api.AFFILIATEADJUSTBLACKOFFER_DELETE,
    method: 'delete',
    params: {
      id,
    },
  });
}
